<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import '~vuetify/styles';
@import '~@mdi/font/css/materialdesignicons.min.css';

.v-footer {
  background-color: #fff;
  color: #000000;
  height: 30px;
}
</style>
