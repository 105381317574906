import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { mdi } from 'vuetify/iconsets/mdi';
import { VTimePicker, VDateInput } from 'vuetify/labs/components';
import { pl } from 'date-fns/locale'; // Import polskiej lokalizacji

export default createVuetify({
    components: {
        ...components,
        VTimePicker,
        VDateInput,  // Zastąpiono VDatePicker na VDateInput
    },
    directives,
    icons: {
        defaultSet: 'mdi',
        sets: { mdi },
    },
    locale: {
        defaultLocale: 'pl',  // Ustawienie domyślnej lokalizacji na polski
        messages: { pl },     // Dodanie polskich wiadomości
    },
});
