<template>
  <v-container>
    <v-card className="pa-4">
      <v-card-title>Moje Dane</v-card-title>
      <v-card-text>
        <p>Nazwa użytkownika: {{ user.user_name }}</p>
        <p>Email: {{ user.user_mail }}</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'MyData',
  data() {
    return {
      user: {}
    };
  },
  mounted() {
    const userData = localStorage.getItem('user');
    if (userData) {
      try {
        this.user = JSON.parse(userData);
      } catch (e) {
        console.error("Error parsing user data from localStorage", e);
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  max-width: 600px;
  margin: auto;
}
</style>
