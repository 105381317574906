<template>
  <v-container class="fill-height d-flex align-center justify-center login-container">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-card-title class="headline text-center">Logowanie</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                  v-model="carrier_number"
                  label="Numer Przewoźnika"
                  prepend-inner-icon="mdi-account"
                  :rules="[rules.required]"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Hasło"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="togglePasswordVisibility"
                  :rules="[rules.required]"
                  required
              ></v-text-field>
              <v-btn type="submit" color="primary" block class="mt-4">
                Zaloguj się
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      carrier_number: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || 'Pole jest wymagane',
      },
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      try {
        console.log('Próba logowania z danymi:', this.carrier_number, this.password); // Logowanie danych wejściowych

        const response = await axios.post('https://app.aahjjwiumf.cfolks.pl/api/login', {
          carrier_number: this.carrier_number,
          password: this.password,
        });

        console.log('Odpowiedź serwera:', response.data); // Logowanie odpowiedzi serwera

        const { token, user } = response.data;

        if (token && user) {
          localStorage.setItem('token', `Bearer ${token}`); // Dodaj "Bearer " przed zapisaniem tokenu
          localStorage.setItem('user', JSON.stringify(user));
          this.$router.push('/dashboard/create-document');
        } else {
          throw new Error('Brak tokenu lub danych użytkownika');
        }
      } catch (error) {
        console.error('Błąd logowania:', error);
        alert('Nieprawidłowe dane logowania');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    }
  },
};
</script>

<style scoped>

.fill-height {
  height: 100vh;
}
.login-container {
  background-image: url('../assets/img/cow.webp');
}
.v-card {
  padding: 20px;
  border-radius: 10px;
}
.v-card-title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}
.v-text-field {
  margin-bottom: 20px;
}
.v-btn {
  background-color: #1976d2;
  color: white;
}
.v-btn:hover {
  background-color: #115293;
}
</style>

